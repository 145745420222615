import apiClient from './apiClient';
import apiUrls from './apiUrls';

const fetchInvoices = () => apiClient.executeGet(
    apiUrls.billing.invoices,
    (responseContent) => ({ invoices: responseContent }),
);

const fetchInvoicePdf = (invoiceId) => {
    const urlWithParams = `${apiUrls.billing.invoicePdf}?invoiceId=${encodeURIComponent(invoiceId)}`;
    return apiClient.executeGet(
        urlWithParams,
        (responseContent) => ({ invoicePdf: responseContent }),
    );
};

const fetchStripeConstants = () => apiClient.executeGet(apiUrls.billing.stripeConstants);

const createSubscription = (paymentMethodId, billingDetails) => apiClient.executeRequest({
    url: apiUrls.billing.createSubscription,
    method: 'POST',
    body: { paymentMethodId, billingDetails },
});

const updatePaymentMethod = (paymentMethodId, billingDetails) => apiClient.executeRequest({
    url: apiUrls.billing.updatePaymentMethod,
    method: 'POST',
    body: { paymentMethodId, billingDetails },
});

const fetchSubscriptionStatus = () => apiClient.executeGet(apiUrls.billing.subscriptionStatus);

const cancelSubscription = () => apiClient.executeRequest({
    url: apiUrls.billing.cancelSubscription,
    method: 'POST',
});

const billingService = {
    fetchInvoices,
    fetchInvoicePdf,
    fetchStripeConstants,
    createSubscription,
    fetchSubscriptionStatus,
    cancelSubscription,
    updatePaymentMethod,
};

export default billingService;
