import { getAppConfig } from '../utils/config';

const { api_url: baseApiUrl } = getAppConfig();

const apiUrls = {

    auth: {
        create: `${baseApiUrl}/auth/create`,
        login: `${baseApiUrl}/auth/login`,
        loginSelectAccount: `${baseApiUrl}/auth/loginSelectAccount`,
        restorePassword: `${baseApiUrl}/auth/restorePassword`,
        resetPassword: `${baseApiUrl}/auth/resetPassword`,
        me: `${baseApiUrl}/auth/me`,
    },

    signup: {
        registerAccount: `${baseApiUrl}/signup/register`,
        acceptInvitation: `${baseApiUrl}/signup/registerWithInvitation`,
        setupComplete: `${baseApiUrl}/signup/setSetupComplete`,
    },

    settings: {
        apiKey: `${baseApiUrl}/settings/apiKey`,
        setPassword: `${baseApiUrl}/settings/password`,
        setEmail: `${baseApiUrl}/settings/email`,
        waFields: `${baseApiUrl}/settings/wa-fields`,
        integrationOptions: `${baseApiUrl}/settings/integration-options`,
        twilioSubscription: `${baseApiUrl}/settings/twilio-subscription`,
        setTwilioSubscription: `${baseApiUrl}/settings/twilio-subscription`,
        twilioSettings: `${baseApiUrl}/settings/twilio-settings`,
        webPages: `${baseApiUrl}/settings/web-pages`,
        ein: `${baseApiUrl}/settings/ein`,
    },

    campaigns: {
        list: `${baseApiUrl}/campaigns`,
        create: `${baseApiUrl}/campaigns`,
        getDetails: (id) => `${baseApiUrl}/campaigns/${id}`,
        stop: (campaignId) => `${baseApiUrl}/campaigns/${campaignId}/stop`,
    },

    recipients: {
        recipientsOptions: (groupType) => `${baseApiUrl}/recipients/options?groupType=${groupType}`,
        groupTypes: `${baseApiUrl}/recipients/groupTypes`,
        // eslint-disable-next-line max-len
        estimateRecipientsNumber: (recipientsListId) => `${baseApiUrl}/recipients/count?recipientsListId=${recipientsListId}`,
        list: (recipientsListId, searchString, top) => {
            let result = `${baseApiUrl}/recipients/list?recipientsListId=${recipientsListId}`;

            if (top) result = `${result}&top=${top}`;
            if (searchString) result = `${result}&searchString=${encodeURIComponent(searchString)}`;

            return result;
        },
        // eslint-disable-next-line max-len
        getRecipient: (recipientType, recipientId) => `${baseApiUrl}/recipients/single?recipientId=${encodeURIComponent(recipientId)}&recipientType=${recipientType}`,
    },

    messages: {
        // eslint-disable-next-line max-len
        recipientMessages: (recipientType, recipientId, includePhone) => `${baseApiUrl}/messages/recipientMessages?recipientId=${encodeURIComponent(recipientId)}&recipientType=${recipientType}&includePhone=${includePhone}`,
        // eslint-disable-next-line max-len
        campaignMessages: (campaignId, status) => `${baseApiUrl}/messages/campaignMessages?campaignId=${campaignId}&status=${status}`,
        latestResponses: (maxMessages) => `${baseApiUrl}/messages/latestResponses?maxMessages=${maxMessages}`,
        campaignResponses: (campaignId) => `${baseApiUrl}/messages/campaignResponses?campaignId=${campaignId}`,
        sendSingle: `${baseApiUrl}/messages/sendSingle`,
    },

    campaignSuggestions: `${baseApiUrl}/suggestions/list`,

    billing: {
        invoices: `${baseApiUrl}/billing/invoices`,
        invoicePdf: `${baseApiUrl}/billing/getInvoicePdf`,
        stripeConstants: `${baseApiUrl}/billing/stripeConstants`,
        createSubscription: `${baseApiUrl}/billing/registerStripeSubscription`,
        updatePaymentMethod: `${baseApiUrl}/billing/updatePaymentMethod`,
        subscriptionStatus: `${baseApiUrl}/billing/subscriptionStatus`,
        cancelSubscription: `${baseApiUrl}/billing/cancelSubscription`,
    },

    users: {
        list: `${baseApiUrl}/users`,
        create: `${baseApiUrl}/users`,
        update: (userId) => `${baseApiUrl}/users/${userId}`,
    },

    accounts: {
        list: `${baseApiUrl}/accounts`,
        create: `${baseApiUrl}/accounts`,
        delete: `${baseApiUrl}/accounts`,
    },
};

export default apiUrls;
